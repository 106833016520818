.lay-out__container {
  // background: url('../../../assets/images/backgrounds/bg_slide_0.png');
  // background: rgb(229, 67, 65);
  background: linear-gradient(0deg, #55d6e7 0%, #5deae3 100%);
  // height: 100vh;
  // width: 1000vw;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  min-width: 100vw;
}
