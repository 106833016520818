.top_con {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
}

.top_ui {
  width: 100%;
  // height: 80%;
}

// .top_con {
//   position: absolute;
//   top: 5%;
//   left: 31%;
//   right: 7%;
//   transform: translate(-50%, -50%); /* center the top_con div */
//   z-index: 1;
// }

// .top_ui {
//   width: 162%;
//   height: 80%;
// }
.coins_dis {
  position: absolute;
  color: #24ff00;
  top: 25%;
  left: 25%;
  font-size: 30px;
  font-family: "SevenSegment";
}
//MEDIA QUERY
@media screen and (max-width: 992px) {
  .top_con {
    .coins_dis {
      font-size: 20px;
      left: 21%;
    }
  }
}
@media screen and (max-width: 769px) {
  .top_con {
    .coins_dis {
      font-size: 20px;
      left: 21%;
    }
  }
}
@media screen and (max-width: 480px) {
  .top_con {
    .coins_dis {
      font-size: 28px;
      top: 30%;
      left: 21%;
    }
  }
}
@media screen and (max-width: 360px) {
  .top_con {
    .coins_dis {
      font-size: 20px;
      top: 33%;
      left: 21%;
    }
  }
}
