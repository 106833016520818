.hotel__container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .background {
    display: block;
    height: 100%;
    width: 100%;
    // object-fit: cover;
  }
  .hotel_text {
    opacity: 1;
    transition: opacity 1s ease-in-out;

    &.fade-in {
      opacity: 0;
    }

    &.fade-out {
      opacity: 1;
    }
    position: absolute;
    top: 23%;
    left: 35%;
    transform: translate(-50%, -50%);

    .hotel_text_img {
      display: block;
      width: 140%;
      max-width: 500px;
      margin-bottom: 20px;
    }
  }

  .hotel_wishes {
    position: absolute;
    top: 14%;
    left: 20%;

    span {
      font-size: 16px;
      font-weight: normal;
    }
  }
}

@media only screen and (max-width: 769px) {
  .hotel__container {
    .hotel_text {
      top: 23%;
      left: 35%;
      .hotel_text_img {
        width: 140%;
        max-width: 500px;
        margin-bottom: 20px;
      }
    }
    .hotel_wishes {
      top: 14%;
      left: 12%;
      h3 {
        font-size: 24px;
        font-weight: normal;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .hotel__container {
    .hotel_wishes {
      h3 {
        font-size: 19px;
        font-weight: normal;
      }
    }
  }
}
