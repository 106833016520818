.audio_controls_container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;

  .optionIcon_img {
    position: absolute;
    width: 11%;
    top: 20%;
    right: 4.5%;
    img {
      width: 100%;
    }
  }
  .dropdown {
    position: absolute;
    width: 11%;
    top: 20%;
    right: 4.5%;
    height: 170%;
    .optionSlide_img {
      position: absolute;
      width: 100%;
      top: 15%;
      left: 0;
    }
    .hamburger_close_button_img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
    .hamburger_audio_sub_container {
      .hamburger_Audio_On_Button_img {
        position: absolute;
        width: 100%;
        top: 34%;
        left: 0;
      }
      .hamburger_Audio_Off_Button_img {
        position: absolute;
        width: 100%;
        top: 34%;
        left: 0;
      }
    }
    .hamburger_music_sub_container {
      .hamburger_music_on_button_img {
        position: absolute;
        width: 100%;
        top: 70%;
        left: 0;
      }
      .hamburger_music_off_button_img {
        position: absolute;
        width: 100%;
        top: 70%;
        left: 0;
      }
    }
  }
}
