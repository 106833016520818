.dialogue-box {
  position: absolute;
  top: 100px;
  left: 5%;
  width: 90%;
  height: 130px;
  opacity: 1;
  animation-name: slideUp;
  animation-duration: 0.5s;

  .bg-img {
    position: absolute;
    width: 100%;
  }

  .dialogue {
    position: absolute;
    width: 100%;
    height: 80%;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes slideUp {
  from {
    top: 400px;
    opacity: 0;
    width: 0;
    left: 50%;
  }
  to {
    top: 100px;
    opacity: 1;
    width: 90%;
    left: 5%;
  }
}

@media screen and (max-width: 769px) {
  .dialogue {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .dialogue {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .dialogue-box .dialogue {
    height: 88%;
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .dialogue-box {
    height: 118px;
  }
  .dialogue-box .dialogue {
    height: 94%;
  }
}
@media screen and (max-width: 375px) {
  .dialogue-box .dialogue {
    height: 88%;
  }
}

@media screen and (max-width: 360px) {
  .dialogue {
    font-size: 11px;
    height: 70%;
  }
}
@media screen and (max-width: 340px) {
  .dialogue-box .dialogue {
    height: 76%;
  }
}

//ONSCREEEN TEXT STYLE
.dialogue-box-on-screen-text {
  position: absolute;
  top: 100px;
  left: 5%;
  width: 90%;
  height: 130px;
  opacity: 1;
  animation-name: slideUp;
  animation-duration: 0.5s;

  .bg-img {
    position: absolute;
    width: 100%;
  }

  .dialogue-on-screen-text {
    position: absolute;
    width: 100%;
    height: 80%;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes slideUp {
  from {
    top: 400px;
    opacity: 0;
    width: 0;
    left: 50%;
  }
  to {
    top: 100px;
    opacity: 1;
    width: 90%;
    left: 5%;
  }
}

@media screen and (max-width: 769px) {
  .dialogue-on-screen-text {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .dialogue-on-screen-text {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .dialogue-box-on-screen-text .dialogue-on-screen-text {
    height: 77%;
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .dialogue-box-on-screen-text {
    height: 77px;
  }
  .dialogue-box-on-screen-text .dialogue-on-screen-text {
    height: 116%;
  }
}
@media screen and (max-width: 375px) {
  .dialogue-box-on-screen-text .dialogue-on-screen-text {
    height: 105%;
  }
}

@media screen and (max-width: 360px) {
  .dialogue-on-screen-text {
    font-size: 11px;
    height: 70%;
  }
}
@media screen and (max-width: 340px) {
  .dialogue-box-on-screen-text .dialogue-on-screen-text {
    height: 100%;
  }
}

//style HOTEL TEXT
.dialogue-box-hotel-text {
  position: absolute;
  top: 100px;
  left: 5%;
  width: 90%;
  height: 130px;
  opacity: 1;
  animation-name: slideUp;
  animation-duration: 0.5s;

  .bg-img {
    position: absolute;
    width: 100%;
  }

  .dialogue-hotel-text {
    position: absolute;
    width: 100%;
    height: 80%;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes slideUp {
  from {
    top: 400px;
    opacity: 0;
    width: 0;
    left: 50%;
  }
  to {
    top: 100px;
    opacity: 1;
    width: 90%;
    left: 5%;
  }
}

@media screen and (max-width: 769px) {
  .dialogue-hotel-text {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .dialogue-hotel-text {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .dialogue-box-hotel-text .dialogue-hotel-text {
    height: 88%;
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .dialogue-box-hotel-text {
    height: 120px;
  }
  .dialogue-box-hotel-text .dialogue-hotel-text {
    height: 94%;
  }
}
@media screen and (max-width: 375px) {
  .dialogue-box-hotel-text .dialogue-hotel-text {
    height: 92%;
  }
}

@media screen and (max-width: 360px) {
  .dialogue-hotel-text {
    font-size: 11px;
    height: 70%;
  }
}
@media screen and (max-width: 340px) {
  .dialogue-box-hotel-text .dialogue-hotel-text {
    height: 80%;
  }
}
