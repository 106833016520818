@property --percentage {
  initial-value: 0%;
  inherits: false;
  syntax: "<percentage>";
}

body {
  display: grid;
  place-items: center;
  box-sizing: border-box;
}
.bottom_con {
  position: absolute;
  bottom: -9%;
  left: 31%;
  right: 7%;
  transform: translate(-50%, -50%); /* center the top_con div */
  z-index: 1;
  .progress_text {
    position: absolute;
    font-size: 14px;
    z-index: 1;
    top: 18%;
    left: 93%;
  }
  .chart {
    position: absolute;
    top: 49px;
    left: 45px;
  }
  .chart_time {
    position: absolute;
    background: red;
    border-radius: 50%;
    top: 49px;
    left: 45px;
    width: 70px;
    height: 70px;
  }

  .hotel_star_img {
    position: absolute;
    z-index: 1;
    width: 20%;
    height: 26%;
    top: 34%;
    left: 69%;
  }
  .cafe_star_img {
    position: absolute;
    z-index: 1;
    width: 20%;
    height: 26%;
    top: 34%;
    left: 100%;
  }
  .museum_star_img {
    position: absolute;
    z-index: 1;
    width: 20%;
    height: 26%;
    top: 34%;
    left: 130%;
  }
  .beach_star_img {
    position: absolute;
    z-index: 1;
    width: 20%;
    height: 26%;
    top: 65%;
    left: 69%;
  }
  .tower_star_img {
    position: absolute;
    z-index: 1;
    width: 20%;
    height: 26%;
    top: 65%;
    left: 100%;
  }
  .market_star_img {
    position: absolute;
    z-index: 1;
    width: 20%;
    height: 26%;
    top: 65%;
    left: 130%;
  }
}
.timer_image {
  position: absolute;
  width: 30%;
  top: 30%;
  left: 20%;
}

.bottom_ui {
  width: 162%;
  height: 80%;
}

//media query

@media screen and (max-width: 480px) {
  .bottom_con {
    .chart {
      top: 61px;
      left: 58px;
    }
    .chart_time {
      top: 61px;
      left: 58px;
      width: 70px;
      height: 70px;
    }
    .timer_image {
      width: 30%;
      top: 30%;
      left: 20%;
    }
  }
}
@media screen and (max-width: 420px) {
  .bottom_con {
    .chart {
      top: 57px;
      left: 54px;
    }
    .chart_time {
      top: 58px;
      left: 54px;
      width: 70px;
      height: 70px;
    }
    .timer_image {
      width: 30%;
      top: 30%;
      left: 20%;
    }
  }
}
@media screen and (max-width: 400px) {
  .bottom_con {
    .chart {
      top: 54px;
      left: 51px;
    }
    .chart_time {
      top: 54px;
      left: 49px;
      width: 67px;
      height: 65px;
    }
    .timer_image {
      width: 30%;
      top: 30%;
      left: 20%;
    }
  }
}
@media screen and (max-width: 380px) {
  .bottom_con {
    .chart {
      top: 50px;
      left: 47px;
    }
    .chart_time {
      top: 49px;
      left: 45px;
      width: 70px;
      height: 70px;
    }
    .timer_image {
      width: 30%;
      top: 30%;
      left: 20%;
    }
  }
}

@media screen and (max-width: 360px) {
  .bottom_con {
    .chart {
      top: 49px;
      left: 45px;
    }
    .chart_time {
      top: 51px;
      left: 47px;
      width: 60px;
      height: 60px;
    }
    .timer_image {
      width: 30%;
      top: 30%;
      left: 20%;
    }
  }
}
@media screen and (max-width: 340px) {
  .bottom_con {
    .chart {
      top: 41px;
      left: 38px;
    }
    .chart_time {
      top: 45px;
      left: 43px;
      width: 53px;
      height: 53px;
    }
    .timer_image {
      width: 30%;
      top: 30%;
      left: 20%;
    }
  }
}

// ----------------------

.progress_text[data-lang="hi"] {
  left: 100%;
}
