* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cubano';
}
@font-face {
  font-family: 'Futura';
  src: local('Futura'), url(./assets/fonts/Futura.otf) format('truetype');
}

html {
  font-size: 62.5%;
}

body {
  touch-action: manipulation;
}

.instruction_panel_image{
  font-size: 14px;
}
