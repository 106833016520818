.registration__container {
  background: url(../../assets/images/anil_revamp_sets/back/START_BG.jpg)
    no-repeat center center fixed;
  background-size: cover;
  max-width: 70rem;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .form__control:not(:last-child) {
    margin-bottom: 2rem;
  }
  .start_button {
    left: 6% !important;
  }
  .form__control {
    position: relative;

    select,
    input {
      width: 100%;
      font-size: 2rem !important;
      outline: none;
      border: none;
      padding: 2rem;

      margin-bottom: 1rem;
      font-weight: 900;

      background-color: rgb(245, 245, 245);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0;
      &::-ms-expand {
        display: none;
      }
    }
    img {
      width: 20rem;
      position: relative;
      left: 2rem;
      margin: auto;
      cursor: pointer;
    }
    .button__text {
      position: absolute;
      font-size: 3rem;
      color: #ffff;
      top: 19%;
      left: 28%;
      cursor: pointer;
    }
    select {
      background: url("../../assets/images/i.png") #ffff 95%;
      background-repeat: no-repeat;
    }
  }
  .start_button {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 80%;
    margin-left: 49%;
    .start_next {
      width: 70%;
    }
  }
  .error__msg {
    color: white;
    font-size: 2rem;
    text-align: center;
  }
}
.MuiAlert-standardError {
  background-color: #fce4e4 !important;
  border: 2px solid #fcc2c3 !important;
  color: #d8000c !important;
}
.MuiAlert-message {
  font-size: 12px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@media screen and (max-device-height: 512px) {
  .registration__container {
    .form__control:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

.languages{
  div[class$="-control"] {
    border: none !important;
    border-radius: 0;
    min-height: inherit;
    padding: 15px 5px;

    div:first-child{
      padding-top:0 !important;
      padding-bottom: 0 !important;
      position: relative;
      input{
        padding: inherit !important;
      }
    }
    div[class$='-placeholder'],
    div[class$='-singleValue']{
      font-size: 2rem !important;
      color: black;
      padding: inherit !important;
      margin: inherit !important;
    }

    span[class$='-indicatorSeparator']{
      display: none;
    }
    div[class$='-indicatorContainer']{
      color: #5d93f7;
    }
    
  }
  div[class $='-menu']{
    margin-top: 2px;
    border-radius: 0;
    div[class $= 'option']{
      padding: 1rem;
      font-size: 2rem;
    }
  }
}