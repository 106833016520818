.image__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  // background-color: rgb(241, 73, 79);
  background: linear-gradient(0deg, #55d6e7 0%, #5deae3 100%);
  // .logo__main {
  //   width: 40%;
  // }
}
@media screen and (max-device-width: 1400px) and (orientation: landscape) {
  .logo__main {
    width: 30%;
  }
}

@media screen and (max-device-width: 767px) and (orientation: portrait) {
  .logo__main {
    width: 70%;
  }
}
